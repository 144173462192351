import React from 'react';

import styles from './Footer.module.css';

export default function Footer (props) {
    return (
        <footer className={styles.root}>
            (footer)
        </footer>
    );
}
