// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
    apiKey: "AIzaSyBCYKV_5Dx85jfoL1UIVhp22Ai6iXynx18",
    authDomain: "tmbo-2020.firebaseapp.com",
    databaseURL: "https://tmbo-2020.firebaseio.com",
    projectId: "tmbo-2020",
    storageBucket: "tmbo-2020.appspot.com",
    messagingSenderId: "819744948693",
    appId: "1:819744948693:web:a5a5373b278a24ea013d5c",
    measurementId: "G-41CVXNSNLH"
};
